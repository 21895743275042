import PageBar from "../../components/dashboard/page-bar/PageBar";
import ListJobShifts from "../../components/job-shifts/list-jobShifts/ListJobShifts";

function ListJobShiftsPage() {
  return (
    <>
      <div className="pageWrapper">
        <PageBar title=" Job Shifts" />
        <h3 className="page-title">
          Manage Job Shifts<small>Job Shifts</small>
        </h3>
        <ListJobShifts />
      </div>
    </>
  );
}
export default ListJobShiftsPage;
