import PageBar from "../../components/dashboard/page-bar/PageBar";
import ListIndustries from "../../components/industries/list-industries/ListIndustries";


function ListIndustriesPage() {
  return (
    <>
      <div className="pageWrapper">
        <PageBar title="Industries" />
        <h3 className="page-title">
          Manage Industries  <small>Industries</small>
        </h3>
        <ListIndustries />
      </div>
    </>
  );
}
export default ListIndustriesPage;
