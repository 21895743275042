import React from 'react'
import Dashboard from '../../components/dashboard/Dashboard'

const HomePage = () => {
  return (
    <>
        <Dashboard />
    </>
  )
}

export default HomePage