import PageBar from "../../components/dashboard/page-bar/PageBar";
import ListLanguage from "../../components/languages/list-language/ListLanguage";

function ListLanguagePage() {
  return (
    <>
      <div className="pageWrapper">
        <PageBar title=" Languages" />
        <h3 className="page-title">
          Manage Languages <small>Languages</small>
        </h3>
        <ListLanguage />
      </div>
    </>
  );
}
export default ListLanguagePage;
