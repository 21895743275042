import React, { useState } from "react";
import { BiChevronLeft, BiHomeAlt2, BiUser } from "react-icons/bi";
import { FaLanguage } from "react-icons/fa";
import { MdSubject } from "react-icons/md";
import { SlGraduation, SlWrench } from "react-icons/sl";
import { FaIndustry, FaUserTie, FaLevelUpAlt,FaRegMoneyBillAlt } from "react-icons/fa";
import {
  AiOutlinePieChart,
  AiOutlineAreaChart,
  AiOutlineFileText,
  AiOutlineLineChart,
  AiOutlineCamera,
} from "react-icons/ai";
import {
  BsGraphUpArrow,
  BsBank,
  BsGenderAmbiguous,
  BsBriefcase,
  BsBuildings,
} from "react-icons/bs";

import { Link, NavLink } from "react-router-dom";

import { SiGoogletranslate } from "react-icons/si";
import { CiUser } from "react-icons/ci";
import { RxQuestionMarkCircled } from "react-icons/rx";



const SideBar = () => {
  const [admin, setAdmin] = useState(false);
  const [language, setLanguage] = useState(false);
  const [career, setCareer] = useState(false);
  const [functional, setFunctional] = useState(false);
  const [gender, setGender] = useState(false);
  const [industry, setIndustry] = useState(false);
  const [experience, setExperience] = useState(false);
  const [skill, setSkill] = useState(false);
  const [types, setTypes] = useState(false);
  const [shifts, setShifts] = useState(false);
  const [degreeLevel, setDegreeLevel] = useState(false);
  const [degreeType, setDegreeType] = useState(false);
  const [majorSub, setMajorSub] = useState(false);
  const [resultType, setResultType] = useState(false);
  const [maritalStatus, setMaritalStatus] = useState(false);
  const [ownership, setOwnership] = useState(false);
  const [salaryPeriod, setSalaryPeriod] = useState(false);

  const [jobs, setJobs] = useState(false);
  const [company, setCompany] = useState(false);
  const [users, setUsers] = useState(false);

  const [translate, setTranslate] = useState(false);
  const [cms, setCms] = useState(false);
  const [manageblog, setManageblog] = useState(false);
  const [seo, setSeo] = useState(false);
  const [faqs, setFaqs] = useState(false);

  const [homepage, setHomepage] = useState(false);
  const [testimonial, setTestimonial] = useState(false);
  const [slider, setSlider] = useState(false);

  // ---user--packages----

  const [packages, setPackages] = useState(false);

  // ----site settings---
  const [site, setSite] = useState(false);

  return (
    <>
      <div className="pageSidebar ">
        <ul className="mainList">
          <li className="navItem start active">
            <div className="text">
              <BiHomeAlt2 />
              <Link to="/admin" className="title">
                {" "}
                Dashboard
              </Link>
            </div>
          </li>

          <li className="heading">
            <h3 className="uppercase">Admin Users</h3>
          </li>
          <li className="navItem">
            <button
              className={`navLink ${admin ? "active" : ""}`}
              onClick={() => {
                setAdmin(!admin);
              }}
            >
              <div className="text">
                <BiUser />
                <span className="title">Admin Users</span>
              </div>
              <div className="arrow">
                <BiChevronLeft />
              </div>
            </button>
            {admin && (
              <ul className="subMenu">
                <li className="subItem">
                  <Link to="/" className="subLink">
                    <div className="subText">
                      <span className="title">List All Admin Users</span>
                    </div>
                  </Link>
                </li>

                <li className="subItem">
                  <Link to="/" className="subLink">
                    <div className="subText">
                      <span className="title">Add Admin User</span>
                    </div>
                  </Link>
                </li>
              </ul>
            )}
          </li>

          <li className="heading">
            <h3 className="uppercase">modules</h3>
          </li>

          <li className="navItem">
            <button
              className={`navLink ${jobs ? "active" : ""}`}
              onClick={() => {
                setJobs(!jobs);
              }}
            >
              <div className="text">
                <BsBriefcase />
                <span className="title">jobs</span>
              </div>
              <div className="arrow">
                <BiChevronLeft />
              </div>
            </button>
            {jobs && (
              <ul className="subMenu">
                <li className="subItem">
                  <NavLink to="list-jobs" className="subLink">
                    <div className="subText">
                      <span className="title">List jobs</span>
                    </div>
                  </NavLink>
                </li>
                <li className="subItem">
                  <NavLink to="create-job" className="subLink">
                    <div className="subText">
                      <span className="title">Add new jobs</span>
                    </div>
                  </NavLink>
                </li>
              </ul>
            )}
          </li>

          <li className="navItem">
            <button
              className={`navLink ${company ? "active" : ""}`}
              onClick={() => {
                setCompany(!company);
              }}
            >
              <div className="text">
                <BsBuildings />
                <span className="title">Companies</span>
              </div>
              <div className="arrow">
                <BiChevronLeft />
              </div>
            </button>
            {company && (
              <ul className="subMenu">
                <li className="subItem">
                  <NavLink to="list-companies" className="subLink">
                    <div className="subText">
                      <span className="title">List Companies</span>
                    </div>
                  </NavLink>
                </li>
                <li className="subItem">
                  <NavLink to="create-company" className="subLink">
                    <div className="subText">
                      <span className="title">Add new Company</span>
                    </div>
                  </NavLink>
                </li>

                <li className="subItem">
                  <NavLink to="list-payment-history" className="subLink">
                    <div className="subText">
                      <span className="title">
                        List Company Payment History
                      </span>
                    </div>
                  </NavLink>
                </li>
              </ul>
            )}
          </li>

          <li className="navItem">
            <button
              className={`navLink ${users ? "active" : ""}`}
              onClick={() => {
                setUsers(!users);
              }}
            >
              <div className="text">
                <CiUser />
                <span className="title">Users Profiles</span>
              </div>
              <div className="arrow">
                <BiChevronLeft />
              </div>
            </button>
            {users && (
              <ul className="subMenu">
                <li className="subItem">
                  <NavLink to="list-users" className="subLink">
                    <div className="subText">
                      <span className="title">List Users Profiles</span>
                    </div>
                  </NavLink>
                </li>
                <li className="subItem">
                  <NavLink to="create-user" className="subLink">
                    <div className="subText">
                      <span className="title">Add new Users Profiles</span>
                    </div>
                  </NavLink>
                </li>
              </ul>
            )}
          </li>

          <li className="navItem">
            <button
              className={`navLink ${cms ? "active" : ""}`}
              onClick={() => {
                setCms(!cms);
              }}
            >
              <div className="text">
                <AiOutlineFileText />
                <span className="title">C.M.S</span>
              </div>
              <div className="arrow">
                <BiChevronLeft />
              </div>
            </button>
            {cms && (
              <ul className="subMenu">
                <li className="subItem">
                  <NavLink to="list-cms" className="subLink">
                    <div className="subText">
                      <span className="title">List C.M.S Pages</span>
                    </div>
                  </NavLink>
                </li>
                <li className="subItem">
                  <NavLink to="create-cms" className="subLink">
                    <div className="subText">
                      <span className="title">Add New C.M.S Pages</span>
                    </div>
                  </NavLink>
                </li>
                <li className="subItem">
                  <NavLink to="list-cmsContent" className="subLink">
                    <div className="subText">
                      <span className="title">List Translated Pages</span>
                    </div>
                  </NavLink>
                </li>
                <li className="subItem">
                  <NavLink to="create-cmsContent" className="subLink">
                    <div className="subText">
                      <span className="title">Add New Translate Pages</span>
                    </div>
                  </NavLink>
                </li>
              </ul>
            )}
          </li>

          <li className="navItem">
            <button
              className={`navLink ${manageblog ? "active" : ""}`}
              onClick={() => {
                setManageblog(!manageblog);
              }}
            >
              <div className="text">
                <FaLevelUpAlt />
                <span className="title">Manage Blogs</span>
              </div>
              <div className="arrow">
                <BiChevronLeft />
              </div>
            </button>
            {manageblog && (
              <ul className="subMenu">
                <li className="subItem">
                  <NavLink to="blog-list" className="subLink">
                    <div className="subText">
                      <span className="title">List Blogs</span>
                    </div>
                  </NavLink>
                </li>
                <li className="subItem">
                  <NavLink to="add-new-blog" className="subLink">
                    <div className="subText">
                      <span className="title">Add Blog</span>
                    </div>
                  </NavLink>
                </li>
                <li className="subItem">
                  <NavLink to="blog_category" className="subLink">
                    <div className="subText">
                      <span className="title">Categories</span>
                    </div>
                  </NavLink>
                </li>
              </ul>
            )}
          </li>

          <li className="navItem">
            <button
              className={`navLink ${seo ? "active" : ""}`}
              onClick={() => {
                setSeo(!seo);
              }}
            >
              <div className="text">
                <AiOutlineLineChart />
                <span className="title">S.E.O</span>
              </div>
              <div className="arrow">
                <BiChevronLeft />
              </div>
            </button>
            {seo && (
              <ul className="subMenu">
                <li className="subItem">
                  <NavLink to="list-seo" className="subLink">
                    <div className="subText">
                      <span className="title">List Page</span>
                    </div>
                  </NavLink>
                </li>
              </ul>
            )}
          </li>

          <li className="navItem">
            <button
              className={`navLink ${faqs ? "active" : ""}`}
              onClick={() => {
                setFaqs(!faqs);
              }}
            >
              <div className="text">
                <RxQuestionMarkCircled />
                <span className="title">FAQS</span>
              </div>
              <div className="arrow">
                <BiChevronLeft />
              </div>
            </button>
            {faqs && (
              <ul className="subMenu">
                <li className="subItem">
                  <NavLink to="list-faqs" className="subLink">
                    <div className="subText">
                      <span className="title">List FAQS</span>
                    </div>
                  </NavLink>
                </li>
                <li className="subItem">
                  <NavLink to="create-faq" className="subLink">
                    <div className="subText">
                      <span className="title">Add New FAQ</span>
                    </div>
                  </NavLink>
                </li>
                <li className="subItem">
                  <NavLink to="sort-faq" className="subLink">
                    <div className="subText">
                      <span className="title">Sort FAQS</span>
                    </div>
                  </NavLink>
                </li>
              </ul>
            )}
          </li>

          <li className="navItem">
            <button
              className={`navLink ${homepage ? "active" : ""}`}
              onClick={() => {
                setHomepage(!homepage);
              }}
            >
              <div className="text">
                <AiOutlineCamera />
                <span className="title">Home Page Video</span>
              </div>
              <div className="arrow">
                <BiChevronLeft />
              </div>
            </button>
            {homepage && (
              <ul className="subMenu">
                <li className="subItem">
                  <NavLink to="list-videos" className="subLink">
                    <div className="subText">
                      <span className="title">List Video Languages</span>
                    </div>
                  </NavLink>
                </li>
                <li className="subItem">
                  <NavLink to="create-video" className="subLink">
                    <div className="subText">
                      <span className="title">Add New Video Language</span>
                    </div>
                  </NavLink>
                </li>
              </ul>
            )}
          </li>

          

          <li className="navItem">
            <button
              className={`navLink ${testimonial ? "active" : ""}`}
              onClick={() => {
                setTestimonial(!testimonial);
              }}
            >
              <div className="text">
                <AiOutlineFileText />
                <span className="title">Testimonials</span>
              </div>
              <div className="arrow">
                <BiChevronLeft />
              </div>
            </button>
            {testimonial && (
              <ul className="subMenu">
                <li className="subItem">
                  <NavLink to="list-testimonials" className="subLink">
                    <div className="subText">
                      <span className="title">List Testimonials</span>
                    </div>
                  </NavLink>
                </li>
                <li className="subItem">
                  <NavLink to="create-testimonial" className="subLink">
                    <div className="subText">
                      <span className="title">Add New Testimonial</span>
                    </div>
                  </NavLink>
                </li>
                <li className="subItem">
                  <NavLink to="sort-testimonials" className="subLink">
                    <div className="subText">
                      <span className="title">Sort Testimonials</span>
                    </div>
                  </NavLink>
                </li>
              </ul>
            )}
          </li>

          <li className="navItem">
            <button
              className={`navLink ${slider ? "active" : ""}`}
              onClick={() => {
                setSlider(!slider);
              }}
            >
              <div className="text">
                <FaLevelUpAlt />
                <span className="title">Sliders</span>
              </div>
              <div className="arrow">
                <BiChevronLeft />
              </div>
            </button>
            {slider && (
              <ul className="subMenu">
                <li className="subItem">
                  <NavLink to="list-sliders" className="subLink">
                    <div className="subText">
                      <span className="title">List Sliders</span>
                    </div>
                  </NavLink>
                </li>
                <li className="subItem">
                  <NavLink to="create-slider" className="subLink">
                    <div className="subText">
                      <span className="title">Add New Slider</span>
                    </div>
                  </NavLink>
                </li>
                <li className="subItem">
                  <NavLink to="sort-sliders" className="subLink">
                    <div className="subText">
                      <span className="title">Sort Sliders</span>
                    </div>
                  </NavLink>
                </li>
              </ul>
            )}
          </li>

          <li className="heading">
            <h3 className="uppercase">TRANSLATION</h3>
          </li>
          <li className="navItem">
            <button
              className={`navLink ${translate ? "active" : ""}`}
              onClick={() => {
                setTranslate(!translate);
              }}
            >
              <div className="text">
                <SiGoogletranslate />
                <span className="title">Languages</span>
              </div>
              <div className="arrow">
                <BiChevronLeft />
              </div>
            </button>
            {translate && (
              <ul className="subMenu">
                <li className="subItem">
                  <NavLink to="list-languages" className="subLink">
                    <div className="subText">
                      <span className="title">List Languages</span>
                    </div>
                  </NavLink>
                </li>
                <li className="subItem">
                  <NavLink to="create-language" className="subLink">
                    <div className="subText">
                      <span className="title">Add new Languages</span>
                    </div>
                  </NavLink>
                </li>
              </ul>
            )}
          </li>

          <li className="heading">
            <h3 className="uppercase">USER PACKAGES</h3>
          </li>
          <li className="navItem">
            <button
              className={`navLink ${packages ? "active" : ""}`}
              onClick={() => {
                setPackages(!packages);
              }}
            >
              <div className="text">
                <FaRegMoneyBillAlt />
                <span className="title">Packages</span>
              </div>
              <div className="arrow">
                <BiChevronLeft />
              </div>
            </button>
            {packages && (
              <ul className="subMenu">
                <li className="subItem">
                  <NavLink to="list-packages" className="subLink">
                    <div className="subText">
                      <span className="title">List Packages</span>
                    </div>
                  </NavLink>
                </li>
                <li className="subItem">
                  <NavLink to="create-package" className="subLink">
                    <div className="subText">
                      <span className="title">Add new Packages</span>
                    </div>
                  </NavLink>
                </li>
              </ul>
            )}
          </li>

          <li className="heading">
            <h3 className="uppercase">job attributes</h3>
          </li>

          <li className="navItem">
            <button
              className={`navLink ${language ? "active" : ""}`}
              onClick={() => {
                setLanguage(!language);
              }}
            >
              <div className="text">
                <FaLanguage />
                <span className="title">Language Levels</span>
              </div>
              <div className="arrow">
                <BiChevronLeft />
              </div>
            </button>
            {language && (
              <ul className="subMenu">
                <li className="subItem">
                  <NavLink to="list-language-levels" className="subLink">
                    <div className="subText">
                      <span className="title">List Language Levels</span>
                    </div>
                  </NavLink>
                </li>
                <li className="subItem">
                  <NavLink to="create-language-level" className="subLink">
                    <div className="subText">
                      <span className="title">Add new Language Level</span>
                    </div>
                  </NavLink>
                </li>
                <li className="subItem">
                  <NavLink to="sort-language-levels" className="subLink">
                    <div className="subText">
                      <span className="title">Sort Language Levels</span>
                    </div>
                  </NavLink>
                </li>
              </ul>
            )}
          </li>
          <li className="navItem">
            <button
              className={`navLink ${career ? "active" : ""}`}
              onClick={() => {
                setCareer(!career);
              }}
            >
              <div className="text">
                <BsGraphUpArrow />
                <span className="title">Career Levels</span>
              </div>
              <div className="arrow">
                <BiChevronLeft />
              </div>
            </button>
            {career && (
              <ul className="subMenu">
                <li className="subItem">
                  <Link to="list-career-levels" className="subLink">
                    <div className="subText">
                      <span className="title">List Career Levels</span>
                    </div>
                  </Link>
                </li>
                <li className="subItem">
                  <Link to="create-career-level" className="subLink">
                    <div className="subText">
                      <span className="title">Add new Career Level</span>
                    </div>
                  </Link>
                </li>
                <li className="subItem">
                  <Link to="sort-career-levels" className="subLink">
                    <div className="subText">
                      <span className="title">Sort Career Levels</span>
                    </div>
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li className="navItem">
            <button
              className={`navLink ${functional ? "active" : ""}`}
              onClick={() => {
                setFunctional(!functional);
              }}
            >
              <div className="text">
                <BsBank />
                <span className="title">Functional Areas</span>
              </div>
              <div className="arrow">
                <BiChevronLeft />
              </div>
            </button>
            {functional && (
              <ul className="subMenu">
                <li className="subItem">
                  <Link to="list-functional-areas" className="subLink">
                    <div className="subText">
                      <span className="title">List Functional Areas</span>
                    </div>
                  </Link>
                </li>
                <li className="subItem">
                  <Link to="create-functional-area" className="subLink">
                    <div className="subText">
                      <span className="title">Add new Functional Area</span>
                    </div>
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li className="navItem">
            <button
              className={`navLink ${gender ? "active" : ""}`}
              onClick={() => {
                setGender(!gender);
              }}
            >
              <div className="text">
                <BsGenderAmbiguous />
                <span className="title">Genders</span>
              </div>
              <div className="arrow">
                <BiChevronLeft />
              </div>
            </button>
            {gender && (
              <ul className="subMenu">
                <li className="subItem">
                  <Link to="list-genders" className="subLink">
                    <div className="subText">
                      <span className="title">List Genders</span>
                    </div>
                  </Link>
                </li>
                <li className="subItem">
                  <Link to="create-gender" className="subLink">
                    <div className="subText">
                      <span className="title">Add new Gender</span>
                    </div>
                  </Link>
                </li>

                <li className="subItem">
                  <Link to="sort-genders" className="subLink">
                    <div className="subText">
                      <span className="title">Sort Genders</span>
                    </div>
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li className="navItem">
            <button
              className={`navLink ${industry ? "active" : ""}`}
              onClick={() => {
                setIndustry(!industry);
              }}
            >
              <div className="text">
                <FaIndustry />
                <span className="title">Industries</span>
              </div>
              <div className="arrow">
                <BiChevronLeft />
              </div>
            </button>
            {industry && (
              <ul className="subMenu">
                <li className="subItem">
                  <Link to="list-industries" className="subLink">
                    <div className="subText">
                      <span className="title">List Industries</span>
                    </div>
                  </Link>
                </li>
                <li className="subItem">
                  <Link to="create-industry" className="subLink">
                    <div className="subText">
                      <span className="title">Add new Industry</span>
                    </div>
                  </Link>
                </li>

                <li className="subItem">
                  <Link to="sort-industries" className="subLink">
                    <div className="subText">
                      <span className="title">Sort Industries</span>
                    </div>
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li className="navItem">
            <button
              className={`navLink ${experience ? "active" : ""}`}
              onClick={() => {
                setExperience(!experience);
              }}
            >
              <div className="text">
                <AiOutlinePieChart />
                <span className="title">Job Experiences</span>
              </div>
              <div className="arrow">
                <BiChevronLeft />
              </div>
            </button>
            {experience && (
              <ul className="subMenu">
                <li className="subItem">
                  <Link to="list-job-experiences" className="subLink">
                    <div className="subText">
                      <span className="title">List Job Experiences</span>
                    </div>
                  </Link>
                </li>
                <li className="subItem">
                  <Link to="create-job-experience" className="subLink">
                    <div className="subText">
                      <span className="title">Add new Job Experience</span>
                    </div>
                  </Link>
                </li>

                <li className="subItem">
                  <Link to="sort-job-experiences" className="subLink">
                    <div className="subText">
                      <span className="title">Sort Job Experiences</span>
                    </div>
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li className="navItem">
            <button
              className={`navLink ${skill ? "active" : ""}`}
              onClick={() => {
                setSkill(!skill);
              }}
            >
              <div className="text">
                <AiOutlineAreaChart />
                <span className="title">Job Skills</span>
              </div>
              <div className="arrow">
                <BiChevronLeft />
              </div>
            </button>
            {skill && (
              <ul className="subMenu">
                <li className="subItem">
                  <Link to="list-job-skills" className="subLink">
                    <div className="subText">
                      <span className="title">List Job Skills</span>
                    </div>
                  </Link>
                </li>
                <li className="subItem">
                  <Link to="create-job-skill" className="subLink">
                    <div className="subText">
                      <span className="title">Add new Job Skills</span>
                    </div>
                  </Link>
                </li>

                <li className="subItem">
                  <Link to="sort-job-skills" className="subLink">
                    <div className="subText">
                      <span className="title">Sort Job Skills</span>
                    </div>
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li className="navItem">
            <button
              className={`navLink ${types ? "active" : ""}`}
              onClick={() => {
                setTypes(!types);
              }}
            >
              <div className="text">
                <BsBriefcase />
                <span className="title">Job Types</span>
              </div>
              <div className="arrow">
                <BiChevronLeft />
              </div>
            </button>
            {types && (
              <ul className="subMenu">
                <li className="subItem">
                  <Link to="list-job-types" className="subLink">
                    <div className="subText">
                      <span className="title">List Job Types</span>
                    </div>
                  </Link>
                </li>
                <li className="subItem">
                  <Link to="create-job-type" className="subLink">
                    <div className="subText">
                      <span className="title">Add new Job Type</span>
                    </div>
                  </Link>
                </li>

                <li className="subItem">
                  <Link to="sort-job-types" className="subLink">
                    <div className="subText">
                      <span className="title">Sort Job Types</span>
                    </div>
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li className="navItem">
            <button
              className={`navLink ${shifts ? "active" : ""}`}
              onClick={() => {
                setShifts(!shifts);
              }}
            >
              <div className="text">
                <FaUserTie />
                <span className="title">Job Shifts</span>
              </div>
              <div className="arrow">
                <BiChevronLeft />
              </div>
            </button>
            {shifts && (
              <ul className="subMenu">
                <li className="subItem">
                  <Link to="list-job-shifts" className="subLink">
                    <div className="subText">
                      <span className="title">List Job Shifts</span>
                    </div>
                  </Link>
                </li>
                <li className="subItem">
                  <Link to="create-job-shift" className="subLink">
                    <div className="subText">
                      <span className="title">Add new Job Shift</span>
                    </div>
                  </Link>
                </li>

                <li className="subItem">
                  <Link to="sort-job-shifts" className="subLink">
                    <div className="subText">
                      <span className="title">Sort Job Shifts</span>
                    </div>
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li className="navItem">
            <button
              className={`navLink ${degreeLevel ? "active" : ""}`}
              onClick={() => {
                setDegreeLevel(!degreeLevel);
              }}
            >
              <div className="text">
                <FaLevelUpAlt />
                <span className="title">Degree Levels</span>
              </div>
              <div className="arrow">
                <BiChevronLeft />
              </div>
            </button>
            {degreeLevel && (
              <ul className="subMenu">
                <li className="subItem">
                  <Link to="list-degree-levels" className="subLink">
                    <div className="subText">
                      <span className="title">List Degree Levels</span>
                    </div>
                  </Link>
                </li>
                <li className="subItem">
                  <Link to="create-degree-level" className="subLink">
                    <div className="subText">
                      <span className="title">Add new Degree Level</span>
                    </div>
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li className="navItem">
            <button
              className={`navLink ${degreeType ? "active" : ""}`}
              onClick={() => {
                setDegreeType(!degreeType);
              }}
            >
              <div className="text">
                <AiOutlineFileText />
                <span className="title">Degree Types</span>
              </div>
              <div className="arrow">
                <BiChevronLeft />
              </div>
            </button>
            {degreeType && (
              <ul className="subMenu">
                <li className="subItem">
                  <Link to="list-degree-types" className="subLink">
                    <div className="subText">
                      <span className="title">List Degree Types</span>
                    </div>
                  </Link>
                </li>
                <li className="subItem">
                  <Link to="create-degree-type" className="subLink">
                    <div className="subText">
                      <span className="title">Add new Degree Types</span>
                    </div>
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li className="navItem">
            <button
              className={`navLink ${majorSub ? "active" : ""}`}
              onClick={() => {
                setMajorSub(!majorSub);
              }}
            >
              <div className="text">
                <MdSubject />
                <span className="title">Major Subjects</span>
              </div>
              <div className="arrow">
                <BiChevronLeft />
              </div>
            </button>
            {majorSub && (
              <ul className="subMenu">
                <li className="subItem">
                  <Link to="list-major-subjects" className="subLink">
                    <div className="subText">
                      <span className="title">List Major Subjects</span>
                    </div>
                  </Link>
                </li>
                <li className="subItem">
                  <Link to="create-major-subject" className="subLink">
                    <div className="subText">
                      <span className="title">Add new Major Subject</span>
                    </div>
                  </Link>
                </li>
                <li className="subItem">
                  <Link to="sort-major-subjects" className="subLink">
                    <div className="subText">
                      <span className="title">Sort Major Subject</span>
                    </div>
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li className="navItem">
            <button
              className={`navLink ${resultType ? "active" : ""}`}
              onClick={() => {
                setResultType(!resultType);
              }}
            >
              <div className="text">
                <SlGraduation />
                <span className="title">Result Types</span>
              </div>
              <div className="arrow">
                <BiChevronLeft />
              </div>
            </button>
            {resultType && (
              <ul className="subMenu">
                <li className="subItem">
                  <Link to="list-result-types" className="subLink">
                    <div className="subText">
                      <span className="title">List Result Types</span>
                    </div>
                  </Link>
                </li>
                <li className="subItem">
                  <Link to="create-result-type" className="subLink">
                    <div className="subText">
                      <span className="title">Add new Result Type</span>
                    </div>
                  </Link>
                </li>
                <li className="subItem">
                  <Link to="sort-result-types" className="subLink">
                    <div className="subText">
                      <span className="title">Sort Result Types</span>
                    </div>
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li className="navItem">
            <button
              className={`navLink ${maritalStatus ? "active" : ""}`}
              onClick={() => {
                setMaritalStatus(!maritalStatus);
              }}
            >
              <div className="text">
                <SlGraduation />
                <span className="title">Material Status</span>
              </div>
              <div className="arrow">
                <BiChevronLeft />
              </div>
            </button>
            {maritalStatus && (
              <ul className="subMenu">
                <li className="subItem">
                  <Link to="list-marital-status" className="subLink">
                    <div className="subText">
                      <span className="title">List Material Status</span>
                    </div>
                  </Link>
                </li>
                <li className="subItem">
                  <Link to="create-marital-status" className="subLink">
                    <div className="subText">
                      <span className="title">Add new Material Status</span>
                    </div>
                  </Link>
                </li>
                <li className="subItem">
                  <Link to="sort-marital-status" className="subLink">
                    <div className="subText">
                      <span className="title">Sort Material Status</span>
                    </div>
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li className="navItem">
            <button
              className={`navLink ${ownership ? "active" : ""}`}
              onClick={() => {
                setOwnership(!ownership);
              }}
            >
              <div className="text">
                <SlGraduation />
                <span className="title">Ownership Types</span>
              </div>
              <div className="arrow">
                <BiChevronLeft />
              </div>
            </button>
            {ownership && (
              <ul className="subMenu">
                <li className="subItem">
                  <Link to="list-ownership-types" className="subLink">
                    <div className="subText">
                      <span className="title">List Ownership Types</span>
                    </div>
                  </Link>
                </li>
                <li className="subItem">
                  <Link to="create-ownership-type" className="subLink">
                    <div className="subText">
                      <span className="title">Add new Ownership Type</span>
                    </div>
                  </Link>
                </li>
                <li className="subItem">
                  <Link to="sort-ownership-types" className="subLink">
                    <div className="subText">
                      <span className="title">Sort Ownership Types</span>
                    </div>
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li className="navItem">
            <button
              className={`navLink ${salaryPeriod ? "active" : ""}`}
              onClick={() => {
                setSalaryPeriod(!salaryPeriod);
              }}
            >
              <div className="text">
                <SlGraduation />
                <span className="title">Salary Periods</span>
              </div>
              <div className="arrow">
                <BiChevronLeft />
              </div>
            </button>
            {salaryPeriod && (
              <ul className="subMenu">
                <li className="subItem">
                  <Link to="list-salary-periods" className="subLink">
                    <div className="subText">
                      <span className="title">List Salary Periods</span>
                    </div>
                  </Link>
                </li>
                <li className="subItem">
                  <Link to="create-salary-period" className="subLink">
                    <div className="subText">
                      <span className="title">Add new Salary Period</span>
                    </div>
                  </Link>
                </li>
                <li className="subItem">
                  <Link to="sort-salary-periods" className="subLink">
                    <div className="subText">
                      <span className="title">Sort Salary Periods</span>
                    </div>
                  </Link>
                </li>
              </ul>
            )}
          </li>

          <li className="heading">
            <h3 className="uppercase">MANAGE</h3>
          </li>

          <li className="navItem">
            <button
              className={`navLink ${site ? "active" : ""}`}
              onClick={() => {
                setSite(!site);
              }}
            >
              <div className="text">
                <SlWrench />
                <span className="title">Site Settings</span>
              </div>
              <div className="arrow">
                <BiChevronLeft />
              </div>
            </button>
            {site && (
              <ul className="subMenu">
                <li className="subItem">
                  <NavLink to="edit-site-setting" className="subLink">
                    <div className="subText">
                      <span className="title">Manage Site Settings</span>
                    </div>
                  </NavLink>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </div>
    </>
  );
};

export default SideBar;
