import React from "react";
import PageBar from "./page-bar/PageBar";
import DashboardStats from "./dashboard-stats/DashboardStats";
import RecentRegisterJobs from "./recent-register-jobs/RecentRegisterJobs";
import RecentRegisterUsers from "./recent-register-users/RecentRegisterUsers";
import { Link } from "react-router-dom";

const Dashboard = () => {
  return (
    <>
      <div className="pageWrapper">
        <PageBar title="EduSkills Job Portal Delhi Admin Panel" />
        <h3 className="page-title">
            EduSkills Job Portal Delhi Admin Panel{" "}
            <small>EduSkills Job Portal Delhi Admin Panel</small>{" "}
        </h3>
        <DashboardStats />
        <div className="recentDash">
            <div className="row">
                <div className="col-md-6">
                    <RecentRegisterUsers />
                    
                </div>
                <div className="col-md-6">
                    <RecentRegisterJobs />
                </div>
            </div>
        </div>
      </div>

    </>
  );
};

export default Dashboard;
