import React from "react";
import PageBar from "../../components/dashboard/page-bar/PageBar";
import Jobs from "../../components/jobs/Jobs";

const JobsPage = () => {
  return (
    <>
      <div className="pageWrapper">
        <PageBar title="Jobs" />
        <h3 className="page-title">
          Manage Jobs <small>Jobs</small>
        </h3>
        <Jobs />
      </div>
    </>
  );
};

export default JobsPage;
