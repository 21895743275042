import React from 'react'
import SideBar from '../../components/common/sidebar/SideBar'
import { Outlet } from 'react-router-dom'

const AdminPage = ({sidebarActive}) => {
  return (
    <>
        <main className={`main ${sidebarActive ? "": "active"}`}>
            <div className="aside">
                <SideBar />
            </div>
            <div className="content">                
                <Outlet></Outlet>
            </div>
        </main>
    </>
  )
}

export default AdminPage